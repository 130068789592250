import React from "react"

import ContentContainer from "src/containers/ContentContainer"
import BackLink from "src/components/BackLink"
import Footer from "src/components/Footer"
import TermsContent from "./TermsContent"

export default function Terms() {
  return (
    <>
      <ContentContainer>
        <BackLink link="/" text="Back" />
        <TermsContent />
      </ContentContainer>
      <Footer />
    </>
  )
}
