import React from "react"

import Terms from "./Terms"

export interface Props {
  pageContext: { [key: string]: any }
}

const TermsContainer = ({ pageContext }: Props) => {
  return <Terms {...pageContext} />
}

export default TermsContainer
